import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";

const SixSecurityInfra = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header">
            <h2 class="events-page-landing-section-header-heading">
              Infrastructure Security
            </h2>
          </div>

          <div className="events-page-security-section-features">
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                All of our website data is transmitted over encrypted Transport
                Layer Security (TLS) connections (i.e., HTTPS).
              </div>

              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                We leverage the content-security policy (CSP) and HTTP Strict
                Transport Security (HSTS) features found in modern browsers.
              </div>
            </div>
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> We partner with enterprise vendors to
                mitigate against distributed denial-of-service (DDoS) attacks.
              </div>
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                Internal-only sections of our website have separate access
                controls and are not exposed to the public Internet.
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityInfra;
