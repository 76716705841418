import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import { Done as DoneIcon } from "@mui/icons-material/";
import { Button } from "@mui/material";
import Moment from "moment";
import { Box, Flex } from "@100mslive/roomkit-react";
import { useGlobalValue } from "../../GlobalContext";
import {
  CalenderIcon,
  CalenderOne,
  CalenderTwo,
  ClockIcon,
  Edit,
  Enter,
  GraphIcon,
  Outlook,
  PoweredByIcon,
  ReplayIcon,
} from "../../icons";
import { EVENT_CALENDER_ADD } from "./EventCalendaer";
import EventPreviewBox from "./EventPreviewBox";
import EventShare from "./EventShare";
import { ReplayViewer } from "./ReplayViewer";
import ReplayViewerPlayers from "./ReplayViewerPlayers";
import { EVENT_STATUS } from "../../common/constants";

const EventAttendee = props => {
  const { setLoginUserVerify } = useGlobalValue();
  const {
    eventHostBy,
    eventName,
    eventDate,
    eventStartTime,
    eventMeetingLink,
    eventCover,
    eventLogo,
    eventDescription,
    goToMeeting,
    openPromotionPopup,
    eventLogoColor,
    eventOwner,
    isSpeaker,
    eventStatus,
    replay,
    onRegister,
    participants,
    userData,
    hostedBy,
    _id,
    isEdit,
    isAnalytics,
    meetingEnable,
    setMeetingEnable,
  } = props;
  const [replayEventView, setReplayEventView] = useState(false);

  const [eventLive, setEventLive] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [hostedLabel, setHostedLabel] = useState("");
  const [isReplayLayout, setIsReplayLayout] = useState(
    eventStatus === EVENT_STATUS?.ended?.value &&
      replay?.status === 1 &&
      replay?.link
  );

  useEffect(() => {
    let hostText = "";
    if (hostedBy?.length > 0) {
      for (let value of hostedBy) {
        if (value?.allow) {
          hostText += hostText ? ", " + value?.orgName : value?.orgName;
        }
      }
    } else if (eventHostBy) {
      hostText = eventHostBy;
    }
    setHostedLabel(hostText);
  }, [hostedBy, eventHostBy]);

  const counterRender = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <></>;
    } else {
      return (
        <div className="attendee-area-col-left-content-counter">
          <Flex align="center" css={{ gap: "8px" }}>
            {days ? (
              <>
                <span className="attendee-area-col-left-content-counter-btn">
                  {days < 10 ? "0" + days : days} <p>DAYS</p>
                </span>
                <span className="attendee-area-col-left-content-counter-divider">
                  :
                </span>
              </>
            ) : null}

            {hours || days ? (
              <>
                <span className="attendee-area-col-left-content-counter-btn">
                  {hours < 10 ? "0" + hours : hours} <p>HRS</p>
                </span>
                <span className="attendee-area-col-left-content-counter-divider">
                  :
                </span>
              </>
            ) : null}

            <span className="attendee-area-col-left-content-counter-btn">
              {minutes < 10 ? "0" + minutes : minutes} <p>MINS</p>
            </span>
            <span className="attendee-area-col-left-content-counter-divider">
              :
            </span>

            <span className="attendee-area-col-left-content-counter-btn">
              {seconds < 10 ? "0" + seconds : seconds} <p>SECS</p>
            </span>
            <div className="attendee-area-col-left-content-calendar">
              <button
                type="button"
                className="attendee-area-col-left-content-calendar-btn"
                onClick={() => setCalendarOpen(!calendarOpen)}
              >
                <CalenderIcon /> Add to calendar
              </button>
              {calendarOpen ? (
                <div className="attendee-area-col-left-content-calendar-dropdown">
                  <div
                    onClick={() => {
                      setCalendarOpen(false);
                      EVENT_CALENDER_ADD("APPLE", {
                        hostedLabel,
                        eventName,
                        eventDate,
                        eventMeetingLink,
                        eventDescription,
                        id: _id,
                      });
                    }}
                    className="attendee-area-col-left-content-calendar-dropdown-btn"
                  >
                    <CalenderTwo />
                    Apple
                  </div>
                  <div
                    onClick={() => {
                      setCalendarOpen(false);
                      EVENT_CALENDER_ADD("GOOGLE", {
                        hostedLabel,
                        eventName,
                        eventDate,
                        eventMeetingLink,
                        eventDescription,
                        id: _id,
                      });
                    }}
                    className="attendee-area-col-left-content-calendar-dropdown-btn"
                  >
                    <CalenderOne />
                    Google
                  </div>
                  <div
                    onClick={() => {
                      setCalendarOpen(false);
                      EVENT_CALENDER_ADD("OUTLOOK", {
                        hostedLabel,
                        eventName,
                        eventDate,
                        eventMeetingLink,
                        eventDescription,
                        id: _id,
                      });
                    }}
                    className="attendee-area-col-left-content-calendar-dropdown-btn"
                  >
                    <Outlook />
                    Outlook
                  </div>
                </div>
              ) : null}
            </div>
          </Flex>
        </div>
      );
    }
  };
  useEffect(() => {
    setIsReplayLayout(
      eventStatus === EVENT_STATUS?.ended?.value &&
        replay?.status === 1 &&
        replay?.link
    );
  }, [eventStatus, replay]);

  return (
    <React.Fragment>
      <Flex className="attendee-area-col" direction="row">
        <div
          className={`attendee-area-col-left ${
            isReplayLayout ? "attendee-area-col-replay" : ""
          }`}
        >
          <Flex direction="column" className="w-100">
            {eventLive ||
            eventOwner ||
            (isSpeaker && (isEdit || isAnalytics)) ||
            isAnalytics ? (
              <div
                className={`attendee-area-col-left-notify ${
                  isReplayLayout ? "attendee-area-col-replay-notify" : ""
                } `}
              >
                {eventLive && eventStatus === EVENT_STATUS?.published?.value ? (
                  <span className="attendee-area-col-left-notify-live">
                    Event is Live
                  </span>
                ) : null}
                <Flex
                  className={`attendee-area-col-left-notify-actions ${
                    isReplayLayout
                      ? "attendee-area-col-replay-notify-actions"
                      : ""
                  } `}
                >
                  {userData && (eventOwner || (isSpeaker && isEdit)) ? (
                    <Link
                      title="Edit Event"
                      to={userData?.isVerify ? `/event/edit/${_id}` : null}
                      className="attendee-area-col-left-notify-actions-btn com-tooltip"
                      onClick={() => {
                        if (!userData?.isVerify) {
                          setLoginUserVerify(true);
                        }
                      }}
                    >
                      <Edit />
                    </Link>
                  ) : null}

                  {userData && (eventOwner || isAnalytics) && (
                    <Link
                      title="Analyze Event"
                      to={userData?.isVerify ? `/event/analytics/${_id}` : null}
                      className="attendee-area-col-left-notify-actions-btn com-tooltip"
                      onClick={() => {
                        if (!userData?.isVerify) {
                          setLoginUserVerify(true);
                        }
                      }}
                    >
                      <GraphIcon />
                    </Link>
                  )}
                </Flex>
              </div>
            ) : null}

            {!isReplayLayout && (
              <EventPreviewBox
                eventCoverPreview={eventCover}
                eventName={eventName}
                eventDate={eventDate}
                hostedBy={hostedBy}
                eventHostBy={eventHostBy}
                eventLogo={eventLogo}
                eventLogoColor={eventLogoColor}
              />
            )}

            <div className="attendee-area-col-left-content">
              <h1 className="attendee-area-col-left-content-heading">
                {eventName}
              </h1>
              <div className="attendee-area-col-left-content-hostedBy">
                Hosted by{" "}
                <span>
                  {hostedBy
                    ? hostedBy?.map((item, index) => (
                        <React.Fragment key={index}>
                          <Link
                            className="text-link"
                            to={`/company/${item?.orgSlug}`}
                          >
                            {item?.orgName}
                          </Link>
                          {index !== hostedBy.length - 1 && ", "}
                        </React.Fragment>
                      ))
                    : hostedLabel}
                </span>
              </div>
              <div className="attendee-area-col-left-content-dateTime">
                <div className="attendee-area-col-left-content-dateTime-single">
                  <CalenderIcon />
                  {eventDate ? (
                    <span>{Moment(eventDate).format("ddd, MMM DD, YYYY")}</span>
                  ) : (
                    "TBD"
                  )}
                </div>
                {eventDate ? (
                  <div className="attendee-area-col-left-content-dateTime-single">
                    <ClockIcon />
                    <span>{Moment(eventDate).format("hh:mm A")}</span>
                  </div>
                ) : null}
              </div>
              {eventStartTime && eventDate ? (
                <div className="attendee-area-col-left-content-counter">
                  <Countdown
                    date={eventStartTime}
                    renderer={counterRender}
                    onTick={({ days, hours, minutes, seconds, completed }) => {
                      setMeetingEnable(
                        ((eventOwner || isSpeaker) &&
                          !days &&
                          !hours &&
                          minutes < 60) ||
                          completed
                      );
                    }}
                    onMount={({ days, hours, minutes, seconds, completed }) => {
                      setMeetingEnable(
                        ((eventOwner || isSpeaker) &&
                          !days &&
                          !hours &&
                          minutes < 60) ||
                          completed
                      );
                      if (completed) {
                        setEventLive(true);
                      }
                    }}
                    onComplete={() => {
                      setEventLive(true);
                      setMeetingEnable(true);
                    }}
                  />
                </div>
              ) : null}
            </div>

            {isReplayLayout && (
              <ReplayViewerPlayers
                replayData={{
                  poster: eventCover,
                  replay: replay,
                  autoPlay: userData && participants,
                  overlay: !userData || !participants ? true : false,
                }}
                controllCallback={!userData || !participants ? onRegister : ""}
              />
            )}
          </Flex>
        </div>

        {!isReplayLayout && (
          <Box className="attendee-area-col-right">
            <Flex direction="column" className="w-100">
              <Box
                className="home-page-layout-content-actionBox"
                css={{ r: "$3" }}
              >
                <div className="home-page-layout-content-actionBox-content">
                  {!userData ? (
                    <>
                      <Button
                        color="primary"
                        variant="outlined"
                        className="events6ix-btn events6ix-btn-primary events6ix-btn-md"
                        fullWidth
                        onClick={onRegister}
                        size="large"
                      >
                        {eventStatus === EVENT_STATUS?.ended?.value ? (
                          replay?.status === 1 && replay?.link ? (
                            <>Watch Replay</>
                          ) : (
                            <>Get Notified of the Replay</>
                          )
                        ) : eventLive ? (
                          <>
                            ENTER ROOM <Enter />
                          </>
                        ) : (
                          <>Register</>
                        )}
                      </Button>

                      <div className="home-page-layout-content-actionBox-content-heading">
                        Join Details
                      </div>
                      <div className="home-page-layout-content-actionBox-content-desc">
                        Come back to this event page when the event starts.
                      </div>
                    </>
                  ) : !participants ? (
                    <>
                      <Button
                        color="primary"
                        variant="outlined"
                        className="events6ix-btn events6ix-btn-primary events6ix-btn-md"
                        fullWidth
                        onClick={onRegister}
                        size="large"
                      >
                        {eventStatus === EVENT_STATUS?.ended?.value ? (
                          replay?.status === 1 && replay?.link ? (
                            <>Watch Replay</>
                          ) : (
                            <>Get Notified of the Replay</>
                          )
                        ) : eventLive ? (
                          <>
                            ENTER ROOM &nbsp; <Enter />
                          </>
                        ) : (
                          <>RSVP</>
                        )}
                      </Button>
                      <div className="home-page-layout-content-actionBox-content-heading">
                        Join Details
                      </div>
                      <div className="home-page-layout-content-actionBox-content-desc">
                        {eventDate ? (
                          <>
                            Come back to this event page on{" "}
                            {Moment(eventDate).format("ddd, MMM DD, YYYY")} at{" "}
                            {Moment(eventDate).format("h:mm A")} to join the
                            event live.
                          </>
                        ) : (
                          <>
                            Come back to this event page when the event starts.
                          </>
                        )}
                      </div>
                    </>
                  ) : userData && participants === 1 ? (
                    eventStatus === EVENT_STATUS?.ended?.value ? (
                      replay?.status === 1 && replay?.link ? (
                        <Button
                          color="primary"
                          variant="outlined"
                          className="events6ix-btn events6ix-btn-primary events6ix-btn-md"
                          endIcon={<ReplayIcon />}
                          fullWidth
                          onClick={() => {
                            setReplayEventView(true);
                          }}
                          size="large"
                        >
                          Watch replay
                        </Button>
                      ) : (
                        <>
                          <div className="home-page-layout-content-actionBox-content-heading text-center">
                            Waiting for Replay
                          </div>
                        </>
                      )
                    ) : meetingEnable ? (
                      <>
                        <Button
                          color="primary"
                          variant="outlined"
                          className="events6ix-btn events6ix-btn-primary events6ix-btn-md"
                          endIcon={<Enter />}
                          fullWidth
                          onClick={goToMeeting}
                          size="large"
                        >
                          ENTER ROOM
                        </Button>

                        <div className="home-page-layout-content-actionBox-content-heading">
                          Join Details
                        </div>
                        <div className="home-page-layout-content-actionBox-content-desc">
                          The event is now live. Click the above button to join.
                        </div>
                      </>
                    ) : (
                      <>
                        <Button
                          color="secondary"
                          variant="outlined"
                          className="events6ix-btn events6ix-btn-md events6ix-btn-secondary"
                          fullWidth
                          disabled
                          size="large"
                          startIcon={<DoneIcon />}
                        >
                          YOU ARE RSVP’D
                        </Button>

                        <div className="home-page-layout-content-actionBox-content-heading">
                          Join Details
                        </div>
                        <div className="home-page-layout-content-actionBox-content-desc">
                          Come back to this event page{" "}
                          {eventDate ? (
                            <>
                              on {Moment(eventDate).format("ddd, MMM DD, YYYY")}{" "}
                              at {Moment(eventDate).format("h:mm A")} to join
                              the event live.
                            </>
                          ) : null}
                        </div>
                      </>
                    )
                  ) : null}
                </div>
                <EventShare
                  eventMeetingLink={eventMeetingLink}
                  eventName={eventName}
                  openPromotionPopup={
                    userData?.isVerify &&
                    eventStatus !== EVENT_STATUS?.ended?.value
                      ? openPromotionPopup
                      : false
                  }
                />
                <div className="home-page-layout-content-actionBox-poweredBy">
                  Powered by <PoweredByIcon />
                </div>
              </Box>
            </Flex>
          </Box>
        )}
      </Flex>

      <div className="com-divider w-100"></div>

      <div className="w-100 event-congrats-description">
        <div className="event-congrats-description-title">Description</div>
        <div
          className="event-congrats-description-desc"
          dangerouslySetInnerHTML={{ __html: eventDescription }}
        ></div>
      </div>

      {replayEventView && replay ? (
        <ReplayViewer
          replayData={{ eventCover: eventCover, replay: replay }}
          close={setReplayEventView}
        />
      ) : null}
    </React.Fragment>
  );
};

export default EventAttendee;
