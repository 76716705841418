import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMedia } from "react-use";
import { usePostHog } from "posthog-js/react";
import { Box, config as cssConfig, Flex } from "@100mslive/roomkit-react";
import { useGlobalValue } from "../../../GlobalContext";
import { MenuIcon, Plus, UserLogin } from "../../../icons";
import { LoginPopup } from "../../Account/Login/LoginPopup";
import LoginVerifyPopup from "../../Account/Login/LoginVerifyPopup";
import ProfilePopup from "../../Account/Profile/ProfilePopup";
import { ToastManager } from "../../Toast/ToastManager";
import EventHeaderAccounts from "./EventHeaderAccounts";
import EventHeaderAttract from "./EventHeaderAttract";
import EventHeaderExpertise from "./EventHeaderExpertise";
import EventHeaderInvest from "./EventHeaderInvest";
import EventHeaderTop from "./EventHeaderTop";
import EventHeaderUsers from "./EventHeaderUsers";
import HeaderLogo from "./HeaderLogo";
import { SESSION_AUTH } from "../../../common/auth";
import { USER_SERVICES } from "../../../services";
import "./style.scss";
const EventHeader = () => {
  const { isUserSession, UPDATE_USER_SESSION, LOGOUT_USER_SESSION } =
    SESSION_AUTH();
  const {
    loggedinNotified,
    isUserReinitialize,
    openSidebar,
    setOpenSidebar,
    loginUserVerify,
  } = useGlobalValue();
  const isMobile = useMedia(cssConfig.media.md);
  const location = useLocation();
  const [dataFetched, setDataFetched] = useState(false);
  const posthog = usePostHog();
  const [userData, setUserData] = useState(null);
  const [loginRequest, setLoginRequest] = useState(false);

  const [hideLogin, setHideLogin] = useState(false);
  const [profileRequest, setProfileRequest] = useState(false);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      setDataFetched(false);
    }
    return () => null;
  }, [isUserSession]);

  useEffect(() => {
    if (isUserReinitialize) {
      setDataFetched(false);
    }
  }, [isUserReinitialize]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    USER_SERVICES.getUser({
      userId: userData?.userId,
      org: userData?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          UPDATE_USER_SESSION(data?.data);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(error => {
        ToastManager.addToast({ title: error.message });
      });
  }, [dataFetched, userData, LOGOUT_USER_SESSION, UPDATE_USER_SESSION]);

  const handleLogin = () => {
    setLoginRequest(!loginRequest);
  };

  useEffect(() => {
    if (userData) {
      posthog?.identify(userData.userId, {
        name: userData.name,
        email: userData.email,
      });
    }
  }, [posthog, userData]);

  useEffect(() => {
    if (loggedinNotified) {
      setProfileRequest(true);
    }
  }, [loggedinNotified]);

  useEffect(() => {
    setHideLogin(location.pathname.includes("/preview/"));
    setProfileRequest(false);
  }, [location]);

  return (
    <React.Fragment>
      <Box className="events6ix-layout-header">
        <EventHeaderTop />
        <Flex
          justify="between"
          align="center"
          className="events6ix-layout-header-inner"
        >
          {isMobile ? (
            <Flex align="center" className="events6ix-layout-header-left">
              <div
                className="events6ix-layout-header-menuButton"
                onClick={() => setOpenSidebar(!openSidebar)}
              >
                <MenuIcon close={openSidebar} />
              </div>
              <div className="events6ix-layout-header-lineV"></div>
              <HeaderLogo />
            </Flex>
          ) : (
            <Flex align="center" className="events6ix-layout-header-left">
              <HeaderLogo />
              <EventHeaderInvest />
              <EventHeaderAttract />
              <EventHeaderExpertise />
            </Flex>
          )}

          <Flex className="events6ix-layout-header-right" align="center">
            {userData?.org && (
              <React.Fragment>
                <div className="events6ix-layout-header-right-item">
                  <EventHeaderAccounts />
                </div>
                <div className="events6ix-layout-header-lineV"></div>

                <div className="events6ix-layout-header-right-item">
                  <Link
                    to="/start"
                    className="events6ix-layout-header-right-item-btn"
                  >
                    <Plus circle={true} />
                    <div className="events6ix-layout-header-right-item-btn-text">
                      NEW
                    </div>
                  </Link>
                </div>
              </React.Fragment>
            )}

            <div className="events6ix-layout-header-right-item">
              {userData ? (
                <EventHeaderUsers />
              ) : !hideLogin ? (
                <div
                  className="events6ix-layout-header-right-item-btn"
                  onClick={handleLogin}
                >
                  <UserLogin />
                  <div className="events6ix-layout-header-right-item-btn-text">
                    LOGIN
                  </div>
                </div>
              ) : null}
            </div>
          </Flex>
        </Flex>
      </Box>

      {loginRequest && !userData ? (
        <LoginPopup dropOut={true} cancel={true} handleLogin={handleLogin} />
      ) : null}

      {loginUserVerify && !userData?.isVerify && (
        <LoginVerifyPopup cancel={true} />
      )}
      {userData && profileRequest && !hideLogin ? (
        <ProfilePopup onClose={() => setProfileRequest(false)} />
      ) : null}
    </React.Fragment>
  );
};

export default EventHeader;
