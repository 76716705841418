import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";

const SixSecurityControls = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header">
            <h2 class="events-page-landing-section-header-heading">
              Internal Controls
            </h2>
          </div>

          <div className="events-page-security-section-features">
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                Multiple signatories are required to transfer cryptocurrency out
                of our Cold Storage System.
              </div>

              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                Our CEO (Tyler Winklevoss) and President (Cameron Winklevoss)
                are unable to individually or jointly transfer cryptocurrency
                out of our Cold Storage System.
              </div>
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                Our offices do not store or contain anything of value, including
                private keys. All private keys are stored offsite and
                geographically distributed in monitored, access-controlled
                facilities.
              </div>
            </div>
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> All employees undergo criminal and credit
                background checks and are subject to ongoing background checks
                throughout their employment.
              </div>
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                All remote-access requires public-key authentication via
                credentials stored on hardware tokens. Passwords, one-time
                passwords (OTPs), or other phishable credentials are not
                permitted.
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityControls;
