import { InfoIcon } from "@100mslive/react-icons";
import { Tooltip } from "@100mslive/roomkit-react";
import IconButton from "../../IconButton";
import { StockIcon } from "../../icons";
import {
  useIsSidepaneTypeOpen,
  useSidepaneToggle,
} from "../AppData/useSidepane";
import { SIDE_PANE_OPTIONS } from "../../common/constants";

export const WidgetToggle = props => {
  const { hasWidget } = props;
  const isWidgetOpen = useIsSidepaneTypeOpen(SIDE_PANE_OPTIONS.WIDGET);
  const toggleWidget = useSidepaneToggle(SIDE_PANE_OPTIONS.WIDGET);
  console.log(hasWidget, "hasWidget");

  return hasWidget ? (
    <Tooltip key="widget" title={`${isWidgetOpen ? "Close" : "Open"} widget`}>
      <div className="preview_tile_footer_frame">
        <IconButton
          onClick={toggleWidget}
          active={!isWidgetOpen}
          data-testid="widget_btn"
          className={`preview_tile_footer_btn ${isWidgetOpen ? "active" : ""}`}
        >
          <StockIcon />
        </IconButton>
        <span className="preview_tile_footer_indicator">Stocks</span>
      </div>
    </Tooltip>
  ) : (
    <></>
  );
};
export const AboutToggle = props => {
  const isWidgetOpen = useIsSidepaneTypeOpen(SIDE_PANE_OPTIONS.ABOUT);
  const toggleWidget = useSidepaneToggle(SIDE_PANE_OPTIONS.ABOUT);

  return (
    <Tooltip key="about" title={`${isWidgetOpen ? "Close" : "Open"} about`}>
      <div className="preview_tile_footer_frame">
        <IconButton
          className={`preview_tile_footer_btn ${isWidgetOpen ? "active" : ""}`}
          onClick={toggleWidget}
          active={!isWidgetOpen}
          data-testid="about_btn"
        >
          <InfoIcon />
        </IconButton>
        <span className="preview_tile_footer_indicator">About</span>
      </div>
    </Tooltip>
  );
};
