import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";
import { useMedia } from "react-use";
import {
  Button,
  MenuItem,
  Select,
  TablePagination,
  TextareaAutosize,
} from "@mui/material";
import Moment from "moment";
import { Box, config as cssConfig, Flex } from "@100mslive/roomkit-react";
import { AscendingIcon, DescendingIcon, Star } from "../../icons";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { ANALYTICS_SERVICES, EVENT_SERVICES } from "../../services";
import { GET_PUBLIC_SESSION, SET_PUBLIC_SESSION } from "../../common/constants";

export function AnalyticsTable(props) {
  const { eventId } = useParams();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const { isSubscription, totalRows } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [sorting, setSorting] = useState(false);
  const isMobile = useMedia(cssConfig.media.md);
  const [sortedField, setSortedField] = React.useState(null);
  const [sortDirection, setSortDirection] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [listData, setListData] = useState([]);
  const rowsPerPageOptions = [50, 100, 250, 500, 1000];
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(() => {
    let v = parseInt(GET_PUBLIC_SESSION("EU_DEFAULT_LIST"));
    return rowsPerPageOptions.includes(v) ? v : isMobile ? 50 : 250;
  });

  const columns = [
    { lable: "Name", target: "name", level: "profile" },
    { lable: "Email", target: "email", level: "profile" },
    { lable: "Mobile", target: "phoneNumber", level: "profile" },
    { lable: "Investor Type", target: "investorType", level: "profile" },
    { lable: "Cheque Size", target: "typicalSize", level: "profile" },
    {
      lable: "Requested Meeting",
      target: "interestedMeeting",
      level: "analytics",
    },
    { lable: "Company Name", target: "work", level: "profile" },
    { lable: "Job Title", target: "jobTitle", level: "profile" },
    {
      lable: "Date & Time Attended",
      target: "attendDate",
      level: "participate",
    },
    { lable: "Date & Time Registered", target: "date", level: "participate" },
    { lable: "iNPS Scores", target: "likelyNumber", level: "analytics" },
    {
      lable: "Shareholder Status",
      target: "currentlyInvested",
      level: "analytics",
    },
    {
      lable: "City, Country",
      target: "country",
      level: "profile",
      leve2: "lastLogin",
    },
    {
      lable: "Rating",
      target: "rating",
      level: "analytics",
    },
    {
      lable: "Target",
      target: "target",
      level: "analytics",
    },
    {
      lable: "Status",
      target: "status",
      level: "analytics",
    },
    {
      lable: "Comments",
      target: "comment",
      level: "analytics",
    },
    {
      lable: "Feedback",
      target: "feedback",
      level: "analytics",
    },
    {
      lable: "Invested Amount",
      target: "investedAmount",
      level: "analytics",
    },
    {
      lable: "Action",
    },
  ];

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  const handleAnalystInputs = (value, index, type) => {
    let d = [...listData];
    if (type === "status") {
      d[index].analytics["status"] = value;
    }
    if (type === "comment") {
      d[index].analytics["comment"] = value;
    }
    if (type === "investedAmount") {
      d[index].analytics["investedAmount"] =
        parseFloat(value) && parseFloat(value) >= 0 ? value : 0;
    }
    if (type === "target") {
      d[index].analytics["target"] = value;
    }
    setListData(d);
  };

  const saveEventAnalytics = async item => {
    if (!userData) {
      return;
    }
    setLoading(true);

    try {
      const data = await EVENT_SERVICES.analyticsUpdate({
        userId: userData.userId,
        org: userData?.org?.id,
        id: item?.analytics?.eventId,
        analytics: {
          id: item?.analytics?._id,
          target: item?.analytics?.target,
          status: item?.analytics?.status,
          comment: item?.analytics?.comment,
          investedAmount: item?.analytics?.investedAmount,
        },
      });
      if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!sorting || !sortDirection || !sortedField) return;
    setSorting(false);
    let listDataArray = [...listData];
    listDataArray.sort((a, b) => {
      let aa = sortedField?.leve2
        ? a?.[sortedField.level]?.[sortedField?.leve2]?.[sortedField.target]
        : a?.[sortedField.level]?.[sortedField.target];
      let bb = sortedField?.leve2
        ? b?.[sortedField.level]?.[sortedField?.leve2]?.[sortedField.target]
        : b?.[sortedField.level]?.[sortedField.target];
      aa = aa || "";
      bb = bb || "";

      if (aa < bb) {
        return sortDirection === "ascending" ? -1 : 1;
      }
      if (aa > bb) {
        return sortDirection === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setListData(listDataArray);
  }, [sorting, listData, sortDirection, sortedField]);

  const sortRequest = item => {
    setSortDirection(
      sortedField?.target === item?.target
        ? sortDirection === "descending"
          ? "ascending"
          : "descending"
        : "ascending"
    );

    setSortedField(item);
    setSorting(true);
  };

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    setLoading(true);
    try {
      ANALYTICS_SERVICES.list({
        userId: userData.userId,
        org: userData?.org?.id,
        page: page,
        limit: limit,
        id: eventId,
      })
        .then(data => {
          if (data.code === 200) {
            setListData(data?.data?.list || []);
            setSorting(true);
            setLoading(false);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }

    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION, limit, page, eventId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setDataFetched(false);
  };
  const handleChangeRowsPerPage = event => {
    let value = event.target.value;
    setLimit(value);
    setPage(0);
    setDataFetched(false);
    SET_PUBLIC_SESSION("EU_DEFAULT_LIST", JSON.stringify(value));
  };

  return (
    <>
      {loading && <FullPageProgress fixed={true} />}
      <div className="relationship">
        <Box className="relationship-usertable" style={{ marginTop: "20px" }}>
          <table className="relationship-usertable-table">
            <thead>
              <tr>
                {columns.map((item, key) => (
                  <th
                    className={
                      item?.target && sortedField?.target === item?.target
                        ? "highlight"
                        : null
                    }
                    key={key}
                    onClick={() => {
                      if (item?.target) {
                        sortRequest(item);
                      }
                    }}
                  >
                    <div>
                      {item?.lable}
                      {item?.target ? (
                        sortDirection === "descending" &&
                        sortedField?.target === item?.target ? (
                          <DescendingIcon />
                        ) : (
                          <AscendingIcon />
                        )
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listData.map((item, key) => (
                <tr key={key}>
                  <td>
                    <Flex>
                      <div className="relationship-usertable-table-image firstname-image">
                        <img src={item?.profile?.profileImage} alt="" />
                      </div>
                      <span>{item?.profile?.name}</span>
                    </Flex>
                  </td>
                  <td>{item?.profile?.email}</td>
                  <td>{item?.profile?.phoneNumber || "-"}</td>
                  <td>{item?.profile?.investorType || "-"}</td>
                  <td>
                    {item?.profile ? (
                      <NumericFormat
                        value={item?.profile?.typicalSize}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                        renderText={(value, props) => (
                          <span {...props}>{value}</span>
                        )}
                      />
                    ) : (
                      "-"
                    )}
                  </td>

                  <td>{item?.analytics?.interestedMeeting || "-"}</td>

                  <td>
                    <span>{item?.profile?.work || "-"}</span>
                  </td>
                  <td>{item?.profile?.jobTitle || "-"}</td>

                  <td>
                    {item?.participate?.attendDate ? (
                      <>
                        {Moment(item?.participate?.attendDate).format(
                          "MMM DD, YYYY hh:mm A"
                        )}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {item?.participate?.date ? (
                      <>
                        {Moment(item?.participate?.date).format(
                          "MMM DD, YYYY hh:mm A"
                        )}
                      </>
                    ) : null}
                  </td>
                  <td>{item?.analytics?.likelyNumber || "-"}</td>
                  <td>
                    {item?.analytics?.currentlyInvested ? (
                      item?.analytics?.currentlyInvested === "yes" ? (
                        <>Shareholder</>
                      ) : (
                        <>Not Shareholder</>
                      )
                    ) : (
                      "-"
                    )}
                  </td>

                  <td>
                    {item?.profile?.lastLogin?.city},{" "}
                    {item?.profile?.lastLogin?.country}
                  </td>
                  <td>
                    {item?.analytics?.rating ? (
                      <Flex title={`Rating ${item?.analytics?.rating}`}>
                        {Array.from(
                          { length: item?.analytics?.rating },
                          (_, index) => {
                            return <Star key={index} active />;
                          }
                        )}
                      </Flex>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {item?.analytics?._id ? (
                      <label className="custom-form-checkbox">
                        <input
                          type="checkbox"
                          className="hms-ui-c-iftXFt custom-form-checkbox-input"
                          onChange={e => {
                            handleAnalystInputs(
                              !item?.analytics?.target,
                              key,
                              "target"
                            );
                          }}
                          checked={item?.analytics?.target}
                        />
                        <span className="custom-form-checkbox-label"></span>
                      </label>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {item?.analytics?._id ? (
                      <Select
                        className="custom-select relationship-usertable-table-input-status"
                        sx={{
                          height: 45,
                          minWidth: 120,
                        }}
                        value={item?.analytics?.status || ""}
                        onChange={e => {
                          handleAnalystInputs(e.target.value, key, "status");
                        }}
                        displayEmpty
                      >
                        <MenuItem value="">
                          <span>Select Status</span>
                        </MenuItem>
                        <MenuItem value="Target Investor">
                          Target Investor
                        </MenuItem>
                        <MenuItem value="Outreach Completed">
                          Outreach Completed
                        </MenuItem>
                        <MenuItem value="In Contact">In Contact</MenuItem>
                        <MenuItem value="Meeting Accepted">
                          Meeting Accepted
                        </MenuItem>
                        <MenuItem value="Meeting Rejected">
                          Meeting Rejected
                        </MenuItem>
                        <MenuItem value="Pitch">Pitch</MenuItem>
                        <MenuItem value="Term Sheet">Term Sheet</MenuItem>
                        <MenuItem value="Committed">Committed</MenuItem>
                        <MenuItem value="Funded">Funded</MenuItem>
                      </Select>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {item?.analytics?._id ? (
                      <TextareaAutosize
                        value={item?.analytics?.comment}
                        onChange={e => {
                          handleAnalystInputs(e.target.value, key, "comment");
                        }}
                        className="custom-form-input relationship-usertable-table-input-comment"
                        placeholder="Enter Comment"
                        minRows={1}
                        maxRows={2}
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {item?.analytics?.feedback ? (
                      <TextareaAutosize
                        readOnly
                        value={item?.analytics?.feedback}
                        className="custom-form-input relationship-usertable-table-input-comment"
                        minRows={1}
                        maxRows={3}
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {item?.analytics?._id ? (
                      <NumericFormat
                        min={0}
                        value={
                          item?.analytics?.investedAmount
                            ? "$" + item?.analytics?.investedAmount
                            : 0
                        }
                        prefix="$"
                        className="custom-form-input relationship-usertable-table-input-amount"
                        onValueChange={values => {
                          handleAnalystInputs(
                            values?.value,
                            key,
                            "investedAmount"
                          );
                        }}
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {item?.analytics?._id ? (
                      <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        className="events6ix-btn events6ix-btn-primary"
                        type="button"
                        onClick={() => {
                          saveEventAnalytics(item);
                        }}
                      >
                        Save
                      </Button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        {totalRows && isSubscription ? (
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showLastButton={true}
            showFirstButton={true}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        ) : null}

        {!isSubscription ? (
          <div className="relationship-upgrade-overlay"></div>
        ) : null}
      </div>
    </>
  );
}
