import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";

const SixSecurityWallet = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header">
            <h2 class="events-page-landing-section-header-heading">
              Gemini Hot Wallet
            </h2>
          </div>

          <div className="events-page-security-section-features">
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                We use HSMs that have achieved a FIPS 140-2 Level 3 rating or
                higher.
              </div>
            </div>
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> We follow the principle of
                least-privilege by applying tiered, role-based access-controls
                to our production environment. Administrative access requires
                multi-factor authentication.
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityWallet;
