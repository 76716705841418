import React from "react";

const SixSecurityCrypto = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section events-page-landing-section-space-top">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header">
            <h2 class="events-page-landing-section-header-heading">
              Crypto Security
            </h2>
            <div class="events-page-landing-section-header-text">
              The majority of your crypto is held in our offline, air-gapped
              Cold Storage system. Only a small portion is held in our online
              Hot Wallet, which is insured.
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityCrypto;
