import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";

const SixSecurityStorage = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header">
            <h2 class="events-page-landing-section-header-heading">
              Gemini Cold Storage
            </h2>
          </div>
          <div className="events-page-security-section-features">
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> We use HSMs that have achieved a FIPS
                140-2 Level 3 rating or higher.
              </div>

              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                We use a multisignature digital signature scheme (multisig) to
                eliminate single points of failure and improve our resilience
                against the loss or compromise of any individual private key.
              </div>
            </div>
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> All private keys are generated onboard
                our HSMs and stored and managed there for their lifetime.
              </div>

              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> All HSMs are geographically distributed
                and stored in monitored, access-controlled facilities.
              </div>
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> All HSMs require the coordinated action
                of multiple employees to operate.
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityStorage;
