import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";

const SixSecurityAccount = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header">
            <h2 class="events-page-landing-section-header-heading">
              Account Security
            </h2>
          </div>

          <div className="events-page-security-section-features">
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                Two-Factor Authentication (2FA) is required when you log in to
                your account or make crypto withdrawals.
              </div>
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                We support Hardware Security Keys via WebAuthn so that you can
                secure your account with the strongest 2FA protection.
              </div>
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                We hash and salt your password and use encryption to secure your
                personal information and other sensitive information, both in
                transit and at rest.
              </div>
            </div>
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> You can create an Approved Address list
                that restricts your crypto withdrawals to approved addresses
                only, or disables all crypto withdrawals from your account.
              </div>
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> Rate-limiting is applied to certain
                account operations, such as your login attempts, in order to
                thwart brute force attacks.
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityAccount;
