import React from "react";
import { TrustListIcon } from "../../../icons";

const SixSecurityCertification = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-security-section-features2">
            <div className="events-page-security-section-features2-icon">
              <TrustListIcon type="certificate" />
            </div>

            <div className="events-page-security-section-features2-content">
              <h2 class="events-page-landing-section-header-heading">
                Compliance and Certifications
              </h2>
              <div className="events-page-landing-privacy-text">
                Gemini is a New York trust company that undergoes regular bank
                exams and is subject to the cyber security regulations
                promulgated by the New York Department of Financial Services. We
                are the world’s first cryptocurrency exchange and custodian to
                complete a SOC 1 Type 2 exam, SOC 2 Type 2 exam, and earn an ISO
                27001 certification.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space-top">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-security-section-features2">
            <div className="events-page-security-section-features2-icon">
              <TrustListIcon type="policy" />
            </div>

            <div className="events-page-security-section-features2-content">
              <h2 class="events-page-landing-section-header-heading">
                Vulnerability Disclosure Policy
              </h2>
              <div className="events-page-landing-privacy-text">
                At Gemini, we welcome contributions from security researchers.
                If you believe you have found a security vulnerability that
                impacts Gemini, we encourage you to contact us immediately. Our
                team will investigate all legitimate reports and do our best to
                respond in a timely manner.
              </div>
              <div className="events-page-landing-privacy-text">
                To participate in our private bug bounty program or learn more
                about the terms of our program, including our scope, bounties,
                or safe harbor guarantee, please email bugbounty@gemini.com.
              </div>
              <div className="events-page-landing-privacy-text">
                Our commitment to security researchers is simple: we will not
                take action against anyone who reports an issue in a responsible
                manner. We will do our best to reply to you in a timely fashion
                and periodically update you on our progress with respect to
                investigating or remediating any issues you may have identified.
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityCertification;
