import React from "react";
import SixAbout from "../../components/6ix/About";
import SixCareers from "../../components/6ix/Careers";
import SixDemo from "../../components/6ix/demo";
import SixBroadCast from "../../components/6ix/demo/SixBroadCast";
import SixMeeting from "../../components/6ix/demo/SixMeeting";
import SixDisclaimer from "../../components/6ix/Disclaimer";
import SixPrivacy from "../../components/6ix/Privacy";
import SixSecurity from "../../components/6ix/Security";
import SixTerms from "../../components/6ix/Terms";
import WhySix from "../../components/6ix/WhySix";
import SEO from "../../components/Common/SEO";
import PageHeader from "../../components/PageHeader";

export const SixPage = () => {
  return (
    <React.Fragment>
      <SEO title="Home | 6ix" />
      <div className="events-page-whysix events6ix-layout-page">
        <PageHeader type="6ix" />
        <div className="events6ix-layout-page-content">
          <WhySix />
        </div>
      </div>
    </React.Fragment>
  );
};

export const SixDemoPage = () => {
  return (
    <React.Fragment>
      <SEO title="Demo | 6ix" />
      <div className="events-page-demo events6ix-layout-page">
        <PageHeader type="6ix" />
        <div className="events6ix-layout-page-content">
          <SixDemo />
        </div>
      </div>
    </React.Fragment>
  );
};
export const SixAboutPage = () => {
  return (
    <React.Fragment>
      <SEO title="About | 6ix" />
      <div className="events-page-about events6ix-layout-page">
        <PageHeader type="6ix" />
        <div className="events6ix-layout-page-content">
          <SixAbout />
        </div>
      </div>
    </React.Fragment>
  );
};
export const SixMeetingPage = () => {
  return (
    <React.Fragment>
      <SEO title="Meetings | 6ix" />
      <div className="events-page-meetings events6ix-layout-page">
        <PageHeader type="6ix" />
        <div className="events6ix-layout-page-content">
          <SixMeeting />
        </div>
      </div>
    </React.Fragment>
  );
};

export const SixBroadcastPage = () => {
  return (
    <React.Fragment>
      <SEO title="Broadcast Interviews | 6ix" />
      <div className="events-page-broadcast events6ix-layout-page">
        <PageHeader type="6ix" />
        <div className="events6ix-layout-page-content">
          <SixBroadCast />
        </div>
      </div>
    </React.Fragment>
  );
};

export const SixCareersPage = () => {
  return (
    <React.Fragment>
      <SEO title="Careers | 6ix" />
      <div className="events-page-broadcast events6ix-layout-page">
        <PageHeader text="Careers" />
        <div className="events6ix-layout-page-content">
          <SixCareers />
        </div>
      </div>
    </React.Fragment>
  );
};
export const SixPrivacyPage = () => {
  return (
    <React.Fragment>
      <SEO title="Privacy Policy | 6ix" />
      <div className="events-page-priacy-policy events6ix-layout-page">
        <PageHeader text="Privacy Policy" />
        <div className="events6ix-layout-page-content">
          <SixPrivacy />
        </div>
      </div>
    </React.Fragment>
  );
};
export const SixTermsPage = () => {
  return (
    <React.Fragment>
      <SEO title="Terms of Service | 6ix" />
      <div className="events-page-terms events6ix-layout-page">
        <PageHeader text="Terms of Service" />
        <div className="events6ix-layout-page-content">
          <SixTerms />
        </div>
      </div>
    </React.Fragment>
  );
};
export const SixDisclaimerPage = () => {
  return (
    <React.Fragment>
      <SEO title="Disclaimer | 6ix" />
      <div className="events-page-disclaimer events6ix-layout-page">
        <PageHeader text="Disclaimer" />
        <div className="events6ix-layout-page-content">
          <SixDisclaimer />
        </div>
      </div>
    </React.Fragment>
  );
};
export const SixSecurityPage = () => {
  return (
    <React.Fragment>
      <SEO title="Security | 6ix" />
      <div className="events-page-disclaimer events6ix-layout-page">
        <PageHeader text="Security" />
        <div className="events6ix-layout-page-content">
          <SixSecurity />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SixPage;
