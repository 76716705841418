import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SixPricing from "../../components/6ix/pricing";
import { LoginPopup } from "../../components/Account/Login/LoginPopup";
import SEO from "../../components/Common/SEO";
import PageHeader from "../../components/PageHeader";
import { useGlobalValue } from "../../GlobalContext";
import { SESSION_AUTH } from "../../common/auth";

const SixPriceingPage = () => {
  const { isUserSession } = SESSION_AUTH();
  const [loginClose, setLoginClose] = useState(false);
  const [enableStep, setEnableStep] = useState(0);

  const navigate = useNavigate();
  const { setLoginUserVerify } = useGlobalValue();

  const handleLogin = () => {
    setTimeout(() => {
      setLoginClose(true);
    }, 100);
  };
  useEffect(() => {
    if (loginClose && !isUserSession) {
      return navigate("/");
    } else if (isUserSession && !isUserSession?.isVerify) {
      setLoginUserVerify(true);
      return navigate("/");
    } else if (isUserSession?.isVerify) {
      setEnableStep(1);
    } else {
      setEnableStep(2);
    }
    return () => null;
  }, [loginClose, isUserSession, navigate, setLoginUserVerify]);

  return (
    <React.Fragment>
      <SEO title="Pricing | 6ix" />
      <div className="events-page-pricing events6ix-layout-page">
        <PageHeader type="6ix" />

        <div className="events6ix-layout-page-content">
          {isUserSession?.isVerify && enableStep === 1 ? (
            <SixPricing />
          ) : !loginClose && enableStep === 2 ? (
            <LoginPopup cancel={true} handleLogin={handleLogin} />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SixPriceingPage;
